a {
  text-decoration: none;
}

.bg-gradient {
  background: #16222a;
  background: -webkit-linear-gradient(59deg, #16222a, #16222a);
  background: linear-gradient(59deg, #16222a, #16222a);
  color: white;
}

.welcome {
  background-image: linear-gradient(59deg, #16222a8b, #16222a6e), url('../public/img/reformers.jpeg');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  height: 50vh;
}

.sermons {
  background-color: #eff7fc;
  display: flex;
  justify-content: center;
  margin: 1rem 0;
}

.youtube {
  width: 1189px;
  height: 644px;
}

@media screen and (max-width: 768px) {
  .youtube {
    width: 100%;
    height: 50%;
  }
}

@media screen and (max-width: 480px) {
  .youtube {
    width: 100%;
    height: 50%;
  }
}

img#padding {
  padding: 0 10px;
}

.card {
  background: #f9fafb !important;
  box-shadow: none !important;
  border-radius: 16px !important;
  width: 65%;
  padding: 20px 10px;
}

#typography {
  color: #475467 !important;
}
